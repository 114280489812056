<template>
  <div
    v-if="
      ($route.path.indexOf('graphical') < 1 &&
        $route.path.indexOf('notify') < 1) ||
      $route.path.indexOf('notify-template')
    "
    class="header-menu no-print"
  >
    <div class="container-fluid">
      <ul class="nav">
        <template v-if="$route.path.indexOf('report') === 1">
          <li class="nav-item" v-if="userInfo.isMultiAgency !== true">
            <router-link class="nav-link" :to="{ name: 'Statistics' }">{{
              $t("__reportStatistics")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'SearchReportResident' }"
              >{{ $t("__reportResident") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'ReportNotifyRecord' }"
              >{{ $t("__notifyReport") }}</router-link
            >
          </li>
        </template>
        <template v-if="$route.path.indexOf('device-management') === 1">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'DeviceResident' }">{{
              $t("__residentDevice")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'ResidentManagement' }">{{
              $t("__residentManagement")
            }}</router-link>
          </li>
          <li class="nav-item" v-if="userInfo && userInfo.isMultiAgency !== true">
            <router-link class="nav-link" :to="{ name: 'NetworkManagement' }">{{
              $t("__networkManagement")
            }}</router-link>
          </li>
        </template>
        <template v-if="$route.path.indexOf('other-device') === 1">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'ForaMeasurement' }">{{
                $t("__fora")
              }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'ChilineMeasurement' }">{{
                $t("__chiline")
              }}</router-link>
          </li>
        </template>
        <template
          v-if="
            $route.path.indexOf('notify-template') === 1 &&
            notifyTemplate &&
            notifyTemplate.length
          "
        >
          <li
            class="nav-item"
            v-for="template in notifyTemplate"
            :key="template.id"
          >
            <router-link
              class="nav-link"
              :to="{
                name: 'NotifyTemplate',
                params: { id: template.id },
              }"
              >{{ !template.is_template ? template.name : $t("__" + template.name) }}
              </router-link
            >
          </li>
          <li class="nav-item" v-if="notifyTemplate.length < 5">
            <button
              class="btn btn-link nav-link border-0"
              type="button"
              @click="addNotifyTemplate"
            >
              <i class="bi bi-plus-circle-fill"></i>
            </button>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang/lang.js'
import { notifyTemplate } from '@/http/api/notifyTemplate.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'HeaderMenu',
  data () {
    return {
      notifyTemplate: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo'])
  },
  watch: {
    userInfo () {
      if (this.$route.path.indexOf('notify-template') === 1) { this.getNotifyTemplate() }
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    getNotifyTemplate () {
      const vm = this
      vm.Loading()
      notifyTemplate.get('', vm.token).then((res) => {
        if (res.status <= 201) {
          const data = res.data.data
          vm.notifyTemplate = data
          if (vm.$route.params.id === 'add-notify-template') {
            vm.$set(vm.notifyTemplate, vm.notifyTemplate.length, {
              id: 'add-notify-template',
              name: i18n.t('__templateNew')
            })
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    addNotifyTemplate () {
      this.$router.push({
        name: 'NotifyTemplate',
        params: { id: 'add-notify-template' }
      })
    }
  },
  created () {},
  mounted () {
    if (this.$route.path.indexOf('notify-template') === 1 && this.userInfo) { this.getNotifyTemplate() }
  }
}
</script>

<style  lang="scss">
.header-menu {
  background-color: $primary;
  .nav-link {
    padding: 20px 30px;
    color: #fff;
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      padding: 15px;
      font-size: 14px;
    }
    &.active {
      color: $primary;
      background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
    }
  }
}
</style>
